import { Injectable, NgZone } from '@angular/core';
import RecordRTC from 'recordrtc';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';


interface RecordedAudioOutput {
 blob: Blob;
 title: string;
 hertz: number;
 audioChannels: number;
 comingIndex?:number
}


@Injectable()
export class AudioRecordingService {
 private stream: MediaStream;
 private recorder: RecordRTC.StereoAudioRecorder;
 private sampleRate: number;
 private audioChannels: number;
 private interval: any;
 private startTime: moment.Moment;
 private silenceStartTime: moment.Moment;
 private silenceThreshold = 0.01; // Adjust this threshold as needed
 private silenceDuration = 3000; // 3 seconds
 private audioContext: AudioContext;
 private analyser: AnalyserNode;
 private dataArray: Uint8Array;
 private _recorded = new Subject<RecordedAudioOutput>();
 private _recordingTime = new Subject<string>();
 private _recordingFailed = new Subject<string>();


 getRecordedBlob(): Observable<RecordedAudioOutput> {
   return this._recorded.asObservable();
 }


 getRecordedTime(): Observable<string> {
   return this._recordingTime.asObservable();
 }


 recordingFailed(): Observable<string> {
   return this._recordingFailed.asObservable();
 }


 startRecording(dictationMic?,comingIndex?) {
   if (this.recorder) {
     return;
   }

   this.silenceStartTime = null; // Reset silence start time
   this._recordingTime.next('00:00');
   navigator.mediaDevices.getUserMedia({ audio: true })
     .then(s => {
       this.stream = s;
       this.record(dictationMic,comingIndex);
     }).catch(error => {
       this._recordingFailed.next(error.message);
     });
 }


 abortRecording() {
   this.stopMedia();
 }


 private record(comingCheck?: boolean,comingIndex?) {
   this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
     type: 'audio',
     mimeType: 'audio/wav'
   });


   this.sampleRate = this.recorder.sampleRate;
   this.audioChannels = this.recorder.numberOfAudioChannels;


   this.recorder.record();
   this.startTime = moment();

   if (comingCheck) {
     this.setupSilenceDetection(comingIndex);
   }


   this.interval = setInterval(() => {
     const currentTime = moment();
     const diffTime = moment.duration(currentTime.diff(this.startTime));
     const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
     this._recordingTime.next(time);
   }, 1000);
 }


 private setupSilenceDetection(comingIndex) {
   this.audioContext = new AudioContext();
   const source = this.audioContext.createMediaStreamSource(this.stream);
   this.analyser = this.audioContext.createAnalyser();
   source.connect(this.analyser);
   this.analyser.fftSize = 256;
   const bufferLength = this.analyser.fftSize;
   this.dataArray = new Uint8Array(bufferLength);


   const checkSilence = () => {
     this.analyser.getByteTimeDomainData(this.dataArray);
     const isSilent = this.dataArray.every(amplitude => amplitude < this.silenceThreshold * 128 + 128);


     if (isSilent) {
       if (!this.silenceStartTime) {
         this.silenceStartTime = moment();
       } else {
         const currentTime = moment();
         const silenceTime = moment.duration(currentTime.diff(this.silenceStartTime));


         if (silenceTime.asMilliseconds() >= this.silenceDuration) {
           this.stopRecording(comingIndex);
           return;
         }
       }
     } else {
       this.silenceStartTime = null;
     }


     requestAnimationFrame(checkSilence);
   };


   checkSilence();
 }


 private toString(value: number) {
   let val = value.toString();
   if (!value) {
     val = '00';
   }
   if (value < 10) {
     val = '0' + value;
   }
   return val;
 }


 stopRecording(comingIndex?) {
   if (this.recorder) {
     this.recorder.stop((blob) => {
       if (this.startTime) {
         const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.wav');
         this.stopMedia();
         this._recorded.next({ blob: blob, title: mp3Name, hertz: this.sampleRate, audioChannels: this.audioChannels, comingIndex:comingIndex });
       }
     }, (error) => {
       this.stopMedia();
       this._recordingFailed.next(error.message);
     });
   }
 }


 private stopMedia() {
   if (this.recorder) {
     this.recorder = null;
     clearInterval(this.interval);
     this.startTime = null;
     this.silenceStartTime = null;
     if (this.stream) {
       this.stream.getAudioTracks().forEach(track => track.stop());
       this.stream = null;
     }
     if (this.audioContext) {
       this.audioContext.close();
       this.audioContext = null;
     }
   }
 }
}
