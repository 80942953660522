// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window["env"]["production"] || false,
  APP_NAME: window["env"]["APP_NAME"] || 'Regenerative-AI',
  Title: window["env"]["Title"] || 'Kyndryl Responsible AI',
  BaseURL: window["env"]["BaseURL"] || 'https://api.k9-dev-394817.trilloapps.com',
  AccessToken : window["env"]["AccessToken"] || 'lsRegenerativeAIAccessToken',
  ImagesURL : window["env"]["ImagesURL"] || '',
  Theme : window["env"]["Theme"] || 'style.scss',
  PollingInterval : window["env"]["PollingInterval"] || 0,
  showEmptyAIModels: window["env"]["showEmptyAIModels"] || true,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.