import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateMiddle'
})
export class TruncateMiddlePipe implements PipeTransform {
  transform(value: string, limit: number = 6): string {
    if (!value) return '';

    const dotIndex = value.lastIndexOf('.');
    if (dotIndex === -1) return value;

    const name = value.slice(0, dotIndex);
    const extension = value.slice(dotIndex);

    if (name.length <= limit) return value;

    const start = name.slice(0, Math.ceil(limit / 2));
    const end = name.slice(-Math.floor(limit / 2));

    return `${start}...${end}${extension}`;
  }
}
