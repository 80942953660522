import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(private oSessionService:SessionService, private Router:Router) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> { 
    let oHeaders = 
    {
      'Accept':'*/*',
      'x-app-name':'main',
      'x-org-name':'cloud',
      'content-type':'application/json',
      'Authorization':'Bearer ' + this.oSessionService.SessionService_GetAccessToken()
    }
    if((request.url.includes('ajaxLogin')) ) delete oHeaders['Authorization'];
    if(request.url.includes('/upload')) delete oHeaders['content-type'];
    if((request.url.includes('https://k9devstorageaccount.blob')) )
    {
      delete oHeaders['Authorization']; 
      delete oHeaders['content-type'];
      delete oHeaders['Accept'];
      delete oHeaders['x-app-name'];
      delete oHeaders['x-org-name'];
    }
    let oRequestWithHeaders  = request.clone({ setHeaders: oHeaders });
    return next.handle(oRequestWithHeaders)
    .pipe(retry(0), catchError((error: HttpErrorResponse)=> {
        if((error.status == 401) && (error.error.redirectUrl == "/_login")){
          // alert('Your account might face suspension or deletion.');
          localStorage.clear();
          this.Router.navigateByUrl('/authentication/login');
        }
        return next.handle(oRequestWithHeaders);             
    }))
  }
}
