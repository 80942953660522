import { INavbarData } from "./helper";

export const userNavbarData : INavbarData[]= [
        // {
        //     routerLink: '/user-enterprise-ai',
        //     path:'/user-enterprise-ai',
        //     label: 'Enterprise AI',
        // },
    {
        routerLink: '',
        path:'quarantine',
        label: 'Pending Requests',
        items: [
            {
                routerLink: '/quarantine/queries',
                path:'quarantine/queries',
                label: 'Queries'
            },
            {
                routerLink: '/quarantine/responses',
                path:'quarantine/responses',
                label: 'Responses'
            }
        ]
    },
    {
        routerLink: '/faq',
        path:'faq',
        label: 'FAQs'
    },
    {
        routerLink: '/settings',
        path:'settings',
        label: 'Settings'
    },
]