import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { INavbarData } from './helper';
import { navbarData } from './nav-data';
import { userNavbarData } from './user-nav-data'
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { SessionService } from 'src/app/services/session.service';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations:[
    trigger('faadeInOut',[
      transition(':enter',[
        style({opacity:0}),
        animate('350ms',
        style({opacity:1})
        )
      ]),
      transition(':leave',[
        style({opacity:1}),
        animate('350ms',
        style({opacity:0}))
      ])
    ]),
    trigger('rotate',[
      transition(':enter',[
        animate('1000ms',
        keyframes([
          style({transform: 'rotate(0deg)' , offset:'0'}),
          style({transform: 'rotate(2turn)' , offset:'1'})
        ]))
      ])
    ]),
    trigger('submenu',[
      state('hidden', style({
        height:'0',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden',[style({overflow:'hidden'}),
    animate('{{transitionParams}}')]),
    transition('void => *',animate(0))
    ])
  ],
})
export class SidebarComponent implements OnInit, OnDestroy{
@Input() collapsed:any;
@Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
screenWidth: number =0;
navData = [];
multiple : boolean = false;
expandedList: any = [];
displayCollapseAll : boolean = false;
subscribeSidebarbehaviour : Subscription;

constructor(private sessionService : SessionService,private router: Router,private dataService : DataService){}

ngOnInit(): void {
  let oSideNavData = this.sessionService.SessionService_GetSideNavData();
  oSideNavData ? this.drawSideBar(oSideNavData) : this.getSideBarData();
 const URL = this.router.url;
 this.updatePathOfChild(URL);
  this.dataService.sideBarItem.subscribe(response => {
    if (response != '') {
      this.updateNavigationState(this.navData,response)      
    }
  })
  this.subscribeSidebarbehaviour = this.dataService.removeAdminFromSideBar.subscribe(response => {
    if (response != '') {
      oSideNavData ? this.drawSideBar(oSideNavData) : this.getSideBarData();
    }
  })

  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {      
      if(event.url==='/policy-packs'){        
        let oSelectedSideBarItem=JSON.parse(localStorage.getItem('PreviousPolicyPack'))
        if(oSelectedSideBarItem) localStorage.setItem('SideBarSelectedItem', JSON.stringify(oSelectedSideBarItem))             
        for (const item of this.navData) {    
            if (item.items && item.label === "Policy Pack") {
                item.isOpen = true;
            }
          if (item.path === event.url.replace('/', '')) {            
            this.toggleSubItems(item);
          }
        }
      }
      this.updateNavigationState(this.navData,event.url.replace('/', ''));
    }
  });
}

getSideBarData() {
  this.dataService.GetSideBarData().subscribe(result => {
      if (result.status == 'failed') { } else {
        this.drawSideBar(result.data?.menuItems)
        this.sessionService.SessionService_SetSideNavData(result.data?.menuItems);
      } (error) => { };
  })
}

drawSideBar(navData) {
  let drawSideBarItems : any = [];
  drawSideBarItems = [...navData]  
  let oUserDetails = this.sessionService.SessionService_GetUserDetails();
  if (oUserDetails.role != 'admin' || oUserDetails.isSuperAdmin != 1) {
    const index=drawSideBarItems.findIndex(item=> item.label === 'Admin');
    if (index != -1) drawSideBarItems.splice(index,1);
  }
  this.navData = drawSideBarItems;
  let selectedSideBarItem = JSON.parse(localStorage.getItem('SideBarSelectedItem'));
  if(selectedSideBarItem) {
    this.updateNavigationState(this.navData, selectedSideBarItem.path)
  } else {
    if (oUserDetails.role!='user') this.router.navigateByUrl('/enterprise-ai');
  }
}

updateNavigationState(navItems, targetObjectPath) {  
    for (const item of navItems) {
    if (item.path === targetObjectPath) {
          item.isActive = true;
          item.isOpen = true;
          if(!item.items)localStorage.setItem('SideBarSelectedItem', JSON.stringify(item));
          return true;
      } else if (item.items) {
          if (this.updateNavigationState(item.items, targetObjectPath)) {
              item.isOpen = true;
              this.displayCollapseAll = true
              return true;
          }
      }
  }
  return false;
}
updatePathOfChild(targetObjectPath)
{
  if(targetObjectPath == '/cost-advisor')
  {
    this.router.navigateByUrl('cost-advisor/spending')
  }
  else if(targetObjectPath == '/quarantine')
  {
    this.router.navigateByUrl('quarantine/queries')
  }
  else if(targetObjectPath == '/watermark')
  {
    this.router.navigateByUrl('watermark/queries')
  }
}

async toggleSubItems(item: INavbarData) {  
  if (item.items) {
    item.isOpen = !item.isOpen;
  }
  if (item.routerLink != '') {
    if(item.routerLink=="/policy-packs"){
      await this.recursiveFunction(this.navData);
      item.isActive = !item.isActive;
    }
    if(!item.items)localStorage.setItem('SideBarSelectedItem', JSON.stringify(item));
    localStorage.removeItem('lFilterList');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('pageSize');
    if (item.routerLink == '/policy-packs') this.dataService.sideBarItemForPolicyPack.next('refresh')
  }
  this.displayCollapseAll = this.checkCollapseAll(this.navData,false);
}
checkCollapseAll(incomingArray, incomingBoolean) {
  incomingArray.forEach(item =>{
    if (item.isOpen) incomingBoolean= true;
    if (item.items) {
      if (item.isOpen) incomingBoolean= true;
      this.checkCollapseAll(item.items,incomingBoolean);
    }
  });
  return incomingBoolean;
}

collapseSideBar(oIncomingArray) {
  oIncomingArray.forEach(item =>{
    item.isOpen = false;
    if (item.items) {
      item.isOpen = false;
      this.collapseSideBar(item.items);
    }
  });
  this.displayCollapseAll = false;
}
  
recursiveFunction(incomingArray) {
  incomingArray.forEach(item =>{
    item.isActive = false;
    if(item.items) {
      item.isActive = false;
      this.recursiveFunction(item.items);
    }
  });
}


isActiveRoute(item: INavbarData): boolean {
  return this.router.isActive(this.router.createUrlTree([item.routerLink]), false);
}

  // handleClick(item: INavbarData)
  // {
  //   if(!this.multiple) {
  //     for(let modelItem of this.navData){
        
  //       if(item !== modelItem && modelItem.expanded){
  //         modelItem.expanded = false;
  //         this.expandedList.push(item)
  //       }
  //     }
  //   }
  //   item.expanded = !item.expanded; 
  // }

ngOnDestroy(): void {
  if(this.subscribeSidebarbehaviour)this.subscribeSidebarbehaviour.unsubscribe();
}
  
}
