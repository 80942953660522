import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  sideBarItem  = new BehaviorSubject<string>('');
  sideBarItemForPolicyPack  = new BehaviorSubject<string>('');
  getConversationHistory  = new BehaviorSubject<string>('');
  removeAdminFromSideBar  = new BehaviorSubject<string>('');
  getConversationIdForDetail  = new BehaviorSubject<string>('');
  refreshProfile=new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient) { }

  //************* User Manager API starts */
  GetUsersList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
  }
  // CreateUser(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
  // }
  CreateUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/CreateUser', body);
  }

  UploadUsers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body);
  }
  EditUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  }
  ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
  }
  SuspendUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body);
  }
  DeleteUser(id : any): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser'+"?id="+ id);
  }
  AddNewTenant(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body);
  }
  //************* User Manager API Ends */

  GetWatermarkQueries(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetWatermarkQueries', body);
  }
  GetWatermarkResponses(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetWatermarkResponses', body);
  }

  GetWaterMarkListDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetWatermarkDetail', body);
  }
  //Quarantine screen API's Start

  GetQuarantineList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetQuarantineList', body);
  }
  GetQuarantineQueryList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetQuarantineQueries', body);
  }
  GetQuarantineQueryDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetQuarantineQueryDetail', body);
  }
  GetApproverList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetApproverList', body);
  }
  UpdateQuarantineQueryDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateQuarantineQueryDetail', body);
  }
  GetQuarantineResponses(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetQuarantineResponses', body);
  }
  GetQuarantineResponseDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetQuarantineResponseDetail', body);
  }  
  UpdateQuarantineResponseDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateQuarantineResponseDetail', body);
  }
    
  GetModels(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetModels', body);
  }
  GetUsersForGenerativeAIModels(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUsers', body);
  }
  GetUsersModelForGenerativeAIModels(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserModel', body);
  }
  SetUsersModelForGenerativeAIModels(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetUserModel', body);
  }
  GetUsersCostModel(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserCost', body);
  }
  AcceptTermsAndAgreed(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/authds/saveTermsAgreement', body);
  }
 //Quarantine screen API's End

 //Content Tracer API'S Start
 ContentTracerList(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetContentTracers', body);
}
GetConversations(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConversations', body);
}
SaveContentTracers(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveContentTracers', body);
}
UpdateContentTracers(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateContentTracers', body);
}
DeleteContentTracer(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteContentTracer', body);
}
 //Content Tracer API'S End
 ChangePassword(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body);
}

 UpdateUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/_service/um/editMyProfile', body);
}


//Setting ======>> Privacy Policy Pack API's Start
GetPolicyPacks(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPolicyPacks', body);
}
// GetPolicyPacksSetting(body): Observable<any> {
//   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPolicyPackSetting', body);
// }
// SetPolicyPacksSetting(body): Observable<any> {
//   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetPolicyPackSetting', body);
// }
GetPolicyPackSetting(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPolicyPackSetting', body);
}
SetPolicyPackSetting(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetPolicyPackSetting', body);
}
//Setting ======>> Privacy Policy Pack API's End


//Setting ======>> User Profile API's End
GetUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
}
SetUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetUserProfile', body);
}
//Setting ======>> User Profile API's End

// Gloabal Search API //
GlobalSearchResults(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GlobalSearch', body);
}

forgotPassword(body): Observable<any>
{
  return this.http.post<any>(environment.BaseURL+'/_preauthsvc/user/forgotPassword',body)
}

resetPassword(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
}

getSettingNotifications(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetNotifications', body);
}

changeSettingNotifications(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserNotifications', body);
}

userSettingNotifications(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetUserNotifications', body);
}


//******************Cost Adviser Maximum Spending API's ***************/
UpdateUserMaxSpendings(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateUserMaxSpendings', body);
}

GetUserMaxSpendings(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserMaxSpendings', body);
}

GetImageThroughFileLocation(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetFileLink', body);
}

// ****************** Enterprise AI Conversation ******************** //
ConversationBetweenK9AndUser(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/OPredict', body);
}

GetUsersConversationRegions(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRegions', body);
}

GetUsersConversationZones(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetZones', body);
} 

GetResponseDetail(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetResponseDetail', body);
}
GetUserModelCategories(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserModelCategories', body);
}
TextToSpeechSynthesize(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SynthesizeSpeech', body);
}

Upload(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + "/foldersvc/cloudstorage/upload",body);
}
SpeechToText(body): Observable<any> {
  return this.http.post(environment.BaseURL + "/ds/function/shared/TranscribeFromSpeech",body);
}



// ****************** Enterprise AI Conersation ******************** //
// ConversationBetweenK9AndUserV2(body): Observable<any> {
//   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/PredictV2', body);
// }
// ConversationBetweenK9AndUserV3(body): Observable<any> {
//   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/PredictV3', body);
// }
// ConversationBetweenK9AndUserV5(body): Observable<any> {
//   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/PredictV5', body);
// }
//******************** AI Model Compliance API's ********************/

GetAIModelCompliance(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAIModelCompliance', body);
}
GetAIModelHistoryThread(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConversationHistory', body);
}
GetAIModelHistoryThreadDetails(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConversationDetail', body);
}
DeleteConversation(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteConversation', body);
}

//****************** Cost Adviser Budgets API's ***************/

GetTeamsList(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetTeamsListStatic', body);
}
SetTeamsList(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetTeamsList', body);
}

//****************** Summary API's ***************/

GetSummaryHistory(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSummaryHistory', body);
}

GetSummaryDetail(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSummaryDetail', body);
}

DeleteSummary(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteSummary', body);
}

SummarizeConversation(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SummarizeConversation', body);
}
// ************** Redacted Go Approver API ******************/

UpdatePromptDetail(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdatePromptDetail', body);
}
CheckConversationStatus(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/CheckConversationStatus', body);
}
GetOngoingConversation(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOngoingConversation', body);
}
//Updated Models list in Models.

UpdateConversationModels(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateConversationModels', body);
}
// *********** Settings ------>> Info Types API *************/

GetInfoTypes(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetInfoTypes', body);
}

SaveInfoTypes(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveInfoTypes', body);
}

GetNLPCategories(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetNLPCategories', body);
}

SetNLPCategories(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetNLPCategories', body);
}

GetAMSConfig(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAMSConfig', body);
}

UpdateAMSModel(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateAMSModel', body);
}

// Upload profile image
ChangeProfileImage(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload',body);
}

// Upload profile image Azure
GetSasURLForProfileImageUploadToAzure(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSASUrl',body);
}

//Get Departments
GetDepartments(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDepartments',body);
}

//Get Cloud Instance
GetCloudInstance(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetCloudInfo',body);
}

//Get Cloud Instance
SetAskApprover(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetAskApprover',body);
}

//Get User Voices
GetUserModelsVoices(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserModelsVoices',body);
}

//Get User Voices
GetUserModelsVoiceTypes(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAvailableTTSVoiceTypes',body);
}

//Get User Voices
GetUserModelsVoiceLanguages(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAvailableLanguages',body);
}

//Get User Voices
GetAvailableTTSVoices(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAvailableTTSVoicesNames',body);
}

//Set User Voices
AddUserModelVoice(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddUserModelVoice',body);
}

// Update User DefaultVoice
UpdateUserDefaultVoice(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateUserDefaultVoice',body);
}

// Update User ModelVoice
UpdateUserModelVoice(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateUserModelVoice',body);
}

// Delete User ModelVoice
DeleteUserModelVoice(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteUserModelVoice',body);
}

// Add Custom Policy
AddNewCustomPolicy(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddCustomPolicy',body);
}
// Add Custom Policy
UpdateCustomPolicy(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateCustomPolicy',body);
}
// Delete Custom Policy
DeleteCustomPolicy(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteCustomPolicy',body);
}

// Get Custom Policy Details
GetCustomPolicyDetails(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPolicyDetail',body);
}

GetSideBarData(): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSideBarConfig', {});
}
}