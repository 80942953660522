import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BlankComponent } from './layouts/blank/blank.component';
import { canActivateTeam } from './guards/auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { canActivateTermsAgreed } from './guards/termsAgreed.guard';
import { UserRoleGuard } from './guards/user-role.guard';
import { canActivateUser } from './guards/user-access.guard';

const routes: Routes = 
[
 	{
		path: '',
		component : BlankComponent,
		children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
			{
				path: 'authentication',
				loadChildren:
					() => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
			}
		]
  	},
	  {
		path: '',
		component: FullComponent,canActivate: [canActivateTeam],
		children:
		[
			{
			  path:'enterprise-ai',
			  loadChildren: () => import('./modules/enterprise-ai/enterprise-ai.module').then(m => m.DashboardModule)
			},
			{
			  path:'responsible-ai',canActivate: [canActivateTermsAgreed,canActivateUser],
			  loadChildren: () => import('./modules/responsible-ai-models/responsible-ai.module').then(m => m.ResponsibleAiModule)
			},
			{
			  path:'watermark',canActivate: [canActivateTermsAgreed,canActivateUser],
			  loadChildren: () => import('./modules/watermark/watermark.module').then(m => m.WatermarkModule)
			},
			{
			  path:'quarantine',canActivate: [canActivateTermsAgreed],
			  loadChildren: () => import('./modules/quarantine/quarantine.module').then(m => m.QuarantineModule)
			},
			{
			  path:'content-tracer',canActivate: [canActivateTermsAgreed,canActivateUser],
			  loadChildren: () => import('./modules/content-tracer/content-tracer.module').then(m => m.ContentTracerkModule)
			},
			{
			   path:'model-compliance',canActivate: [canActivateTermsAgreed,canActivateUser],
			   loadChildren: () => import('./modules/model-compliance/model-compliance.module').then(m => m.ModelComplianceModule)
			},
			{
			  path:'cost-advisor',canActivate: [canActivateTermsAgreed,canActivateUser],
			  loadChildren: () => import('./modules/cost-advisor/cost-advisor.module').then(m => m.CostAdvisorModule)
			},
			{
				path:'policy-packs',canActivate: [canActivateTermsAgreed,canActivateUser],
				loadChildren: () => import('./modules/policy-packs/policy-packs.module').then(m => m.AppsModule)
			},
			{
			  path:'settings',canActivate: [canActivateTermsAgreed,canActivateUser],
			  loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
			 },
			{
				path:'admin',canActivate: [canActivateTermsAgreed,UserRoleGuard,canActivateUser],
				loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
			},
			{
				path:'faq',canActivate: [canActivateTermsAgreed,canActivateUser],
				loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
			},
			{
				path:'global-search',canActivate: [canActivateTermsAgreed],
				loadChildren: () => import('./modules/global-search/global-search.module').then(m => m.GlobalSearchModule)
			},
			{
				path:'user-enterprise-ai',
				loadChildren: () => import('./modules/user-enterprise-ai/user-enterprise.module').then(m => m.UserEnterpriseModule)
			}
		]
	  },
  	{ path: '**', redirectTo: '/authentication/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
