import { Component } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionService } from './services/session.service';
import { environment } from 'src/environments/environment';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = undefined;
  title = 'app';
  bOldBrowser: boolean;
  oBroswerNameVersion: { browserName: string; browserVersion: number; };

  constructor(private oCheckIdleState: Idle, private keepalive: Keepalive, private oRouter: Router, private oSessionService: SessionService,private oBroswerVersionService: BrowserCompatibilityService) {
    const title = environment.Title; // Access the title from the environment
    this.bOldBrowser = this.oBroswerVersionService.isOldBrowser();
    this.oBroswerNameVersion =this.oBroswerVersionService.GetBrowserNameAndVersion();
    // sets an idle timeout of 5 seconds, for testing purposes.
    oCheckIdleState.setIdle(5);
    // sets a timeout period of 30 minutes. After 30 minutes and 5 seconds of inactivity, the user will be considered timed out.
    oCheckIdleState.setTimeout(1800);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    oCheckIdleState.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log("On idle end Idle state : ==> ", this.idleState);
    });
    oCheckIdleState.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log("On Time out Idle state : ==> ", this.idleState);
      localStorage.clear();
      this.AppComponent_ResetTimer();
      this.oRouter.navigateByUrl('/authentication/login');
    });
    oCheckIdleState.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log("On idle start Idle state : ==> ", this.idleState);
    });
    oCheckIdleState.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log("on Timeout warning Idle state : ==> ", this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(5);

    keepalive.onPing.subscribe(
      () => {
        this.lastPing = new Date();
        this.oSessionService.cSessionService_setSessionExpiry();
      });

    this.AppComponent_ResetTimer();
  }

  AppComponent_ResetTimer() {
    this.AppComponent_CheckSessionExpiry();
    this.oCheckIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log("AppComponent_ResetTimer");
  }

  AppComponent_CheckSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = this.oSessionService.cSessionService_getSessionExpiry();
    const nCurrentTime = oDate.getTime();

    if (nSessionExpiryTime !== null) {
      console.log("Session_CheckSessionExpiry: Session Expiry Time {%s}", nSessionExpiryTime);
      console.log("Session_CheckSessionExpiry: Current Time {%s}", nCurrentTime);
      // compare the expiry time of the item with the current time
      if (nCurrentTime > nSessionExpiryTime) {
        localStorage.removeItem("ls" + environment.APP_NAME + "SessionExpiryTime");
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/authentication/login');
      }
    }
  }

}
