import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { userNavbarData } from '../modules/shared/sidebar/user-nav-data'; 


const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const sessionService = inject(SessionService);
  const router = inject(Router);
      const role = sessionService.SessionService_GetUserDetails().role;
      if(role && role == 'user')
      {
        let lsConfig = [];
        lsConfig = JSON.parse(JSON.stringify(userNavbarData));
        const urlWithQuery = state.url;
        const baseUrl = urlWithQuery.split('?')[0];
        if (lsConfig && lsConfig.length > 0) {
          const isMenuItemValid = (menuItems, currentPath) => {
            let isMatch = false; // Initialize a variable to track if a match is found
            for (const item of menuItems) {
              if (item.routerLink == currentPath || (item.items && isMenuItemValid(item.items, currentPath))) {
                isMatch = true; // Found a match
                break; // Exit the loop when a match is found
              }
            }
            return isMatch; // Return the result after the loop
          };
          if (isMenuItemValid(lsConfig, baseUrl)) {
            return true;
          } else {
            router.navigate([lsConfig[0].routerLink]); // Redirect to the first menu item
            return false;
          }
        } else {
          return false; // Return false when lsConfig is null to deny navigation
        }
      } 
      else {
        return true;
      }
};
export const canActivateUser: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}