import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';


const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const sessionService = inject(SessionService);
  const router = inject(Router);
    if(sessionService.SessionService_GetUserDetails().isSuperAdmin == 1 && sessionService.SessionService_GetUserDetails().role == 'admin')
    {
        return true;
    }
    else
    {
        router.navigate(['/enterprise-ai']);
        return false;
    }
};
export const UserRoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}