import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  //set session expiry
  cSessionService_setSessionExpiry() {
    const nSessionExpiryTime: number = new Date().getTime() + 1800000;
    localStorage.setItem("ls" + environment.APP_NAME + "SessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }
  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(localStorage.getItem("ls" + environment.APP_NAME + "SessionExpiryTime"));
  }

  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }
  SessionService_SetApplicationSideBarConfigurationFile(oData: any) {
    localStorage.setItem('lsConfigFile', JSON.stringify(oData));
  }
  SessionService_GetApplicationSideBarConfigurationFile() {
    return JSON.parse(localStorage.getItem('lsConfigFile'));
  }
  // // set user details
  // SessionService_SetUserDetails(oData: any) { 
  //   localStorage.setItem('lsUserDetails', JSON.stringify(oData))
  // }
  // // get user details
  // SessionService_GetUserDetails() {
  //   return JSON.parse(localStorage.getItem('lsUserDetails'));
  // }

  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('lsEnterprise', btoa(JSON.stringify(oData)));
  }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('lsEnterprise');
    if (encodedData) return JSON.parse(atob(encodedData));
  }
  
  SessionService_SetSideNavData(oData: any) {
    localStorage.setItem('lsSideEnterprise', btoa(JSON.stringify(oData)));
  }

  SessionService_GetSideNavData() {
    try {
      const encodedData = localStorage.getItem('lsSideEnterprise');
      if (encodedData) return JSON.parse(atob(encodedData));
    } catch (error) {
      return null;
    }
  }
  
  //***********Storing and getting Quaratine Filter data in LocalStorage */
  SessionService_SetQuarantineFilterList(oData: any) {
    localStorage.setItem('lFilterList', JSON.stringify(oData))
  }
  SessionService_GetQuarantineFilterList() {
    return JSON.parse(localStorage.getItem('lFilterList'));
  }

    //***********Storing and getting Current Page  */
    SessionService_SetCurrentPage(oData: any) {
      localStorage.setItem('currentPage', JSON.stringify(oData))
    }
    SessionService_GetCurrentPage() {
      return JSON.parse(localStorage.getItem('currentPage'));
    }

      //***********Storing and getting Current Page  */
      SessionService_SetPageSize(oData: any) {
        localStorage.setItem('pageSize', JSON.stringify(oData))
      }
      SessionService_GetPageSize() {
        return JSON.parse(localStorage.getItem('pageSize'));
      }
}
