import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AlertsComponent } from './alerts/alerts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CustomPaginationComponent } from '../shared/custom-pagination/custom-pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppOutsideClickDirective } from './directives/app-outside-click.directive';
import { RedactedStyleDirective } from 'src/app/directives/redacted-style.directive';
import { TruncateMiddlePipe } from './pipes/truncate-middle.pipe';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [
    HeaderComponent,
    AlertsComponent,
    SidebarComponent,
    CustomPaginationComponent,
    LoaderComponent,
    AppOutsideClickDirective,
    RedactedStyleDirective,
    TruncateMiddlePipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),

  ],
  exports: [HeaderComponent,SidebarComponent,AlertsComponent,CustomPaginationComponent,LoaderComponent,AppOutsideClickDirective,RedactedStyleDirective,TruncateMiddlePipe, MarkdownModule  ]
})
export class SharedModule { }



