import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/services/session.service';
import { DataService } from 'src/app/services/data.service';
import { MustMatch } from 'src/app/services/must-match.validator';
import { Location } from '@angular/common';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() toggleSidebar: any;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  resetPasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  loader : boolean = false;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  collapsed: boolean = true;
  screenWidth: number = 0;
  displayNotification: boolean = false;
  userDetails: any;
  searchString : string = "";
  currentUrl: string;
  imageUrl: any;
  
  constructor(private location:Location, private route: Router, private sessionService: SessionService, private modalService: NgbModal, private formBuilder: FormBuilder, private dataService: DataService,private activatedRoute : ActivatedRoute) {
    this.userDetails = this.sessionService.SessionService_GetUserDetails()
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if(this.screenWidth >= 992){
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }

  ngOnInit(): void {
    this.getProfileSettingDataToPatch();
    // Subscribe to URL changes
    // This callback is called when the user navigates backward or forward.
    this.location.onUrlChange((url: string) => {
      this.dismissModal()
      this.searchString='';
    });

    this.dataService.refreshProfile.subscribe(result =>{
      if(result) {
        setTimeout(() => {
          this.userDetails = this.sessionService.SessionService_GetUserDetails();
          this.imageUrl=this.userDetails?.pictureUrl ;
        }, 1000);
        this.resetPasswordFormControls();
        this.updateProfileFormControls();
      }
    })

    this.resetPasswordFormControls();
    this.updateProfileFormControls();
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.hasOwnProperty('query')) {
        this.searchString = params['query'];
      } else {
        // this.searchString = "";
      }
    })
    this.userDetails = this.sessionService.SessionService_GetUserDetails();
  }

  resetPasswordFormControls() {
    const passwordRejex = /^.{8,}$/;
    const maxPasswordLength = 24;
    this.resetPasswordForm = this.formBuilder.group({
      userId: [this.userDetails.userId],
      emailVerifyKey: [''],
      oldPassword: ['', Validators.required],
      password: ['', [ Validators.required, Validators.pattern(passwordRejex), Validators.maxLength(maxPasswordLength) ]],
      rptPassword: ['', Validators.required],
      orgName: ['']
    },
    { validator: MustMatch('password', 'rptPassword') });
  }

  updateProfileFormControls() {
    let textRejex = /^(?! )(.*)$/;
    this.updateProfileForm = this.formBuilder.group({
      id: [this.userDetails.id, [Validators.required]],
      firstName: [this.userDetails.firstName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]],
      lastName: [this.userDetails.lastName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]]
    })
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loader = true;
      let body = this.userDetails
      body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value
      body.password = this.resetPasswordForm.controls['password'].value
      body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value
      this.dataService.ChangePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            this.loader = false;
            this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
          } else {
            this.modalService.dismissAll();
            this.resetPasswordForm.reset();
            this.loader = false;
            this.getProfileSettingDataToPatch()
            this.displayAlertMessage('Success', 'success', 'success');
          }
        },
        error: (error) => {
          this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
          this.loader = false;
        },
        complete: () => { },
      })
    } else {
      this.resetPasswordForm.markAllAsTouched()
    }
  }

  getProfileSettingDataToPatch(){    
    this.dataService.GetUserProfile({userId:this.userDetails.id}).subscribe({
      next: (result) => {
        if (result.status=='failed') {
        } else {
          this.userDetails = this.sessionService.SessionService_GetUserDetails();
          this.imageUrl=this.userDetails?.pictureUrl??result.data.pictureUrl ;
          this.userDetails.pictureUrl = this.imageUrl;                  
          this.sessionService.SessionService_SetUserDetails(this.userDetails);
        }
      },
      error: (error) => { }
    })
  }

  submitProfileSettingData() {
    if(this.updateProfileForm.valid){
            this.loader = true;   
        this.dataService.SetUserProfile(this.updateProfileForm.value).subscribe({
          next: (result) => {
            if (result.status === 'failed') {
              this.loader = false;
              this.displayAlertMessage(result.message,'danger','danger')
            } else {
              this.displayAlertMessage(result.message,'success', 'success');          
              this.userDetails.firstName=result?.data['firstName'];
              this.userDetails.lastName=result?.data['lastName'];
              this.sessionService.SessionService_SetUserDetails(this.userDetails); 
              this.modalService.dismissAll();
              this.loader = false;
            }
          },
          error: (error) => {
            this.loader = false;
            console.error("SubmitProfileSettingData: ERROR ===>>", error);
            this.displayAlertMessage(error.message,'danger','danger')
          },
        });
    } else {
      this.updateProfileForm.markAllAsTouched();
    }
  }

  // updateUserProfile() {
  //   this.loader = true;
  //   let body = {...this.userDetails}
  //   body.firstName = this.updateProfileForm.controls['firstname'].value
  //   body.lastName = this.updateProfileForm.controls['lastname'].value
  //   body.fullName = body.firstName + " " + body.lastName
  //   delete body.pictureUrl;

  //   this.dataService.UpdateUserProfile(body).subscribe({
  //     next: (result) => {
  //       if (result.failed) {
  //       this.loader = false;
  //         this.displayAlertMessage('Bad Request!', 'error', 'danger');
  //       }
  //       else {
  //         this.modalService.dismissAll();
  //         this.updateProfileForm.reset();
  //         this.userDetails = result.props.user;
  //         result.props.user.pictureUrl=null;
  //         this.sessionService.SessionService_SetUserDetails(result.props.user)
  //         this.updateProfileFormControls();
  //         this.getProfileSettingDataToPatch()
  //         this.loader = false;
  //         this.displayAlertMessage('Success', 'success', 'success');
  //       }
  //     },
  //     error: (error) => {
  //       this.loader = false;
  //       this.displayAlertMessage('Bad Request!', 'error', 'danger');
  //     },
  //     complete: () => { },
  //   }
  //   )
  // }

  // --------------- Change Profile Request ----------------
  // changeProfileImage(event) {
  //   let file = event.target.files[0];

  //   let body = new FormData();
  //   body.append("file", file);
  //   body.append("folder", 'public/admins');
  //   body.append("functionName","UpdateProfilePicture"),
  //   body.append("makePublic", "false");
  //   this.dataService.ChangeProfileImage(body).subscribe({
  //     next: (result) => {
  //       if (result.status == 'failed') { }
  //       else {
  //         this.pictureUrl = result.folderName + "/" + result.fileName ;
  //         console.log(this.pictureUrl,'pictureUrl');
          
  //         // this.getUserProfile()
  //       }
  //     },
  //     error: (error) => { },
  //     complete: () => { }
  //   })

  // }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  logout() {
    localStorage.clear();
    this.route.navigateByUrl('/authentication/login');
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.updateProfileForm.reset();
    this.resetPasswordForm.reset();
    this.updateProfileFormControls();
    this.resetPasswordFormControls();
  }

  openChangePasswordModal(changePassword) {
    this.modalService.open(changePassword, { centered: true, size: 'md' });
    this.resetPasswordFormControls()
  }

  openUpdateProfileModal(updateProfile) {
    this.modalService.open(updateProfile, { centered: true, size: 'md' });
  }

  displayNotifications() {
    setTimeout(() => {
      this.displayNotification = !this.displayNotification
    }, 100);
  }

  clickOutside() {
    this.displayNotification = false;
  }

  removeLeadingSpaces(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    // Remove leading spaces
    const trimmedValue = inputValue.replace(/^\s+/, '');
    // Update the input value
    if (trimmedValue !== inputValue) {
      inputElement.value = trimmedValue;
      // Update the form control value
     this.searchString=trimmedValue;
    }
  }

  globalSearch(event) {
    let searchValue = event.target.value;
    this.searchString = searchValue;
    if (searchValue.length > 0 && event.keyCode == 13) {
      this.route.navigate(['global-search'],{queryParams : {query : searchValue}})
    } else if(searchValue.length == 0 && event.keyCode == 13) {
      if (this.userDetails.role == 'user') {
        this.route.navigate(['/user-enterprise-ai']);
      } else {
        this.route.navigate(['/enterprise-ai']);
      }
      // this.route.navigateByUrl('global-search')
    }
  }
  
  HomePageForUser() {
      if (this.userDetails.role == 'user')  {
        this.route.navigate(['/user-enterprise-ai']);
      }
  }

  loadAvatar(){
      this.imageUrl = '../../../../assets/images/profile.jpeg';
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll()
  }

    // ************************** ALERT MESSAGES ***************************/

  displayAlertMessage(incomingMessage, incomingResponseType, incomingColor) {
    this.displayErrorBlock = true
    this.resMessage = {
      message: incomingMessage,
      responseType: incomingResponseType,
      color: incomingColor
    };
    setTimeout(() => { this.displayErrorBlock = false; }, 3000);
  }
 
}


