import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';


@Injectable({
  providedIn: 'root'
})
export class BrowserCompatibilityService {
  browserVersion: any;
  constructor() {}
  isOldBrowser(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const minimumVersions = {
      chrome: 63,
      firefox: 58,
      safari: 10,
      edge: 15,
      opera:50,
      android:4,
      ios:9,
      ie: 11 // or any version you want to consider as old
    };
    const browserName = browser.getBrowserName().toLowerCase();
    if (minimumVersions[browserName]) {
      const minimumVersion = minimumVersions[browserName];
      const version = parseInt(browser.getBrowserVersion(),10);
      return version < minimumVersion;
    }
    return false;
  }
  GetBrowserNameAndVersion()
  {
    const browser = Bowser.getParser(window.navigator.userAgent);
    let obj  = 
    {
      browserName: browser.getBrowserName(),
      browserVersion: parseInt(browser.getBrowserVersion(),10)
    }
    return obj;
  }
    
}
