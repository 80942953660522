import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRedactedStyle]'
}) 
export class RedactedStyleDirective implements OnInit {
  @Input() redactedText: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.redactText();
  }

  redactText() {
    const text = this.redactedText;
    const redactedText = this.processText(text);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', redactedText);
  }

  processText(text: string): string {
    const redactedText = text.replace(/\*+/g, match => `<span class="bg-redacted-Card p-1 text-center"><span class="vertical-alignment">${match}</span></span>`);
    return redactedText;
  }
}


