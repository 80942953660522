import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';


const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const sessionService = inject(SessionService);
  const router = inject(Router);
    if(sessionService.SessionService_GetAccessToken() != null) 
    {
        // if(sessionService.SessionService_GetUserDetails().termsAgreed == true)
        if(localStorage.getItem('firstTimeLogin') == 'true')
        {
            return true;
        }
        else
        {
            if(sessionService.SessionService_GetUserDetails().role == 'user')
            {
                router.navigate(['/user-enterprise-ai']);
            }
            else
            {
                router.navigate(['/enterprise-ai']);
            }
            return false;
        }
    } 
    else
    {
        router.navigate(['authentication/login']);
        return false;
    }
};
export const canActivateTermsAgreed: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}
